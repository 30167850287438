@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap");

body {
  margin: 0 !important;
  padding: 0 !important;
  font-family: "Open Sans", sans-serif !important;
}

code {
  font-family: "Open Sans", sans-serif !important;
}

.nav-link:hover {
  border-bottom: 5px solid #db9732;
  color: #db9732 !important;
  padding-bottom: 11px;
  margin-bottom: -14px;
}
/* .nav li{
  border-bottom: 2px solid #5D5446;
} */
.unl {
  list-style-type: none;
  width: 275px !important;
}
.unlWrapper {
  margin-top: 20px;
  margin-left: 36px;
}
.unl li {
  border-bottom: none !important;
  margin-bottom: 8px;
}
.unl li:hover {
  background: #e5e5e5;
  cursor: pointer;
}

.inputBoxProteger:focus-visible{
  outline: none;
}
.inputBoxProteger{
  font-size: 16px;
  color: #5d5446;
  width: 100%;
  border: 0px solid;
}
.urlpdfBtn{
  background-color: #5d5446 !important;
  margin-top: 25px;
}
/* .active {
  border-bottom: 5px solid #edc67f;
  color: #edc67f !important;
} */
.teleUnder {
  text-decoration: none;
}
.active a {
  border-bottom: 5px solid #db9732;
  color: #db9732 !important;
  padding-bottom: 11px;
  margin-bottom: -14px;
}
.active .converterIcons {
      color: #db9732 !important;
    margin-top: -25px;
    position: relative;
    float: right;
    margin-right: 22px;
}
.active .textColor1 {
  color: #5d5446 !important;
  height: 30px;
  margin-bottom: 5px;
}

.midSearch {
  margin-top: -200px;
}

.react-pdf__Page__textContent {
  display: none;
}
.react-pdf__Page__canvas {
  max-width: 146px !important;
  max-height: 207px !important;
  margin: 0px auto;
  border: 1px solid #000;
  box-shadow: 0px 0px 10px #cdcaca;
}
.compresser .react-pdf__Page__canvas {
  max-width: 146px !important;
  max-height: 207px !important;
}
.numeroter .react-pdf__Page__canvas {
  max-width: 146px !important;
  max-height: 207px !important;
  opacity: 0.6;
}
.react-pdf__Document {
  max-height: 207px !important;
  max-width: 146px !important;
}
.full {
  height: 100%;
  width: 100%;
}
.popupWrapper {
  display: none;
}

.search {
  float: right;
  color: #818181;
  margin-right: 20px;
  margin-top: -42px;
  font-size: 25px;
  position: relative;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.search:hover {
  cursor: pointer;
}
.searchIcon {
  float: right;
  margin-right: 20px;
  margin-top: -32px;
  position: relative;
  color: #818181;
  font-size: 25px;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.dotIcon {
  float: right;
  margin-right: 7px;
  margin-top: -28px;
  height: 20px !important;
  width: 20px !important;
  position: relative;
}
.comNewFlexnew{
  display: flex;
}
.okproteger{
  background-color: rgba(255, 255, 255, 0) !important;
}
.validationErrorsnew{
  color: red;
  margin-top: -3px;
}
.dotIcon1 {
  position: absolute;
  z-index: 1;
  height: 20px !important;
  width: 20px !important;
}
.midSearch {
  position: relative;
  font-size: 25px;
  color: rgb(73, 73, 73);
  color: #818181;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.searchIcon:hover {
  cursor: pointer;
}
.midSearch:hover {
  cursor: pointer;
}
.dotIcon:hover {
  cursor: pointer;
}
.back {
  background-color: #fff;
  min-height: 120vh;
  border-top-left-radius: 40px;
}
.check .form-check-input {
  width: 22px;
  height: 22px;
  margin-top: 3px;
  border-radius: 0px !important;
  border: 1px solid #e9d9cd;
}
.btnhover:hover {
  background-color: #000 !important;
  color: #fff !important;
}
.dotGrille {
  transform: translateX(76px) translateY(-27px);
  width: 20px;
  height: 20px;
}
.dotGrilleNumber {
  width: 20px;
  height: 20px;
}
.dotGrille1 {
  height: 20px;
  width: 20px;
}
#dropdown-basic {
  border: 1px solid #e9d9cd;
}
.texteDrop {
  width: 100%;
  overflow: auto;
}
.texteDropdown {
  width: 100%;
}
.filigranerimage {
  display: flex;
}
.positionnementDiv {
  display: flex;
  justify-content: space-between;
}
.numerotationsDiv2 {
  display: flex;
  justify-content: space-around;
}
.numeroterdelaPage {
  display: flex;
  justify-content: space-between;
}
.positionnement {
  display: flex;
  justify-content: space-between;
}
.affichageDiv {
  display: flex;
  justify-content: space-between;
}
.filigranerimagediv {
  width: 36%;
}
.signerDiv {
  display: flex;
}
.signerDiv2 {
  display: flex;
}
.signerDiv3 {
  margin-left: 12px;
  text-align: center;
  background-color: rgb(219, 151, 49);
  width: 284px;
  height: 36px;
}
.signerDiv3:hover {
  cursor: pointer;
}
.scrollPDF {
  height: 100vh;
  overflow: auto;
  margin-top: 10px;
}
.scrollPDF1 {
  height: 118vh;
  overflow: auto;
  margin-top: 10px;
}

.loginRoot {
  height: 100vh;
}
.loginWrapper {
  height: 100%;
  max-width: 500px;
  margin: 0 auto;
  padding-top: 200px;
  padding-left: 10px;
  padding-right: 10px;
}
.colorPikerPOS {
  position: absolute;
  z-index: 1;
}
.transparant {
  background-image: url(/src/img/transpatant.jpg);
}
.editor {
  display: flex;
}
.mantine-RichTextEditor-root {
  border: 1px solid #fff;
  padding: 0 0;
}
.quill {
  display: none;
}
#richtext .mantine-RichTextEditor-toolbarGroup button {
  height: 30px;
  width: 30px;
  border: 0;
  margin: 0 0px;
}
.mantine-RichTextEditor-toolbarGroup button {
  height: 30px;
  width: 30px;
  border: 0;
  margin: 0 5px;
}
.mantine-bdajhj {
  padding: 7px 16px !important;
  border-bottom: 0 !important;
}
.editorDiv1 {
  width: 28%;
}
.editorDiv2 {
  width: 70%;
}
.checkBoxes {
  margin: 5px auto;
}
.checkBoxes .form-check-input {
  border: 1px solid #c8b198;
  width: 22px;
  height: 22px;
  border-radius: 1px !important;
}
.diviserLAPage {
  margin-left: 17px;
}
.fichierDeAla {
  display: flex;
}
.fichierDeAla {
  margin-left: 25px;
}
.form-check-input:checked {
  background-color: #5d5446 !important;
  border-color: #5d5446 !important;
}
.form-check-input:focus {
  border-color: #5d5446 !important;
  outline: 0;
  box-shadow: 0 0 0 0 #5d5446 !important;
}
.delapage111 {
  padding: 0 5px;
}
.input-group {
  border-radius: 4px;
  border: 1px solid #4a4a4a96;
}
.form-control:focus {
  box-shadow: none !important;
  border-color: 1px solid #e9d9cd !important;
}
.input-group-text {
  background-color: #ffffff !important;
  border: 1px solid #ffffff !important;
}
.form-control {
  border: 1px solid #ffffff !important;
}
.facebookBtn {
  background: #3b5998;
  color: #fff;
  border: 1px solid #3b5998;
  border-radius: 4px;
  height: 35px;
}
.googleBtn {
  width: 196px;
  border: 1px solid red;
  background: #fff;
  border-radius: 4px;
  height: 35px;
}
.buttonDiv {
  display: flex;
  justify-content: space-around;
  margin: 10px 0;
}
.user {
  color: rgb(255, 255, 255) !important;
  padding: 5px 10px;
  margin: 0 10px;
}
.userName:hover {
  cursor: pointer;
}
.validationErrors {
  color: red;
  margin-top: -10px;
}
.userDiv {
  background-color: #000;
  text-align: start;
  display: none;
  margin-top: 38px;
  margin-left: -88px;
  position: absolute;
}
.userDiv:hover {
  display: block;
}
.userName:hover + .userDiv {
  display: block !important;
  z-index: 1;
}
.userList {
  color: #fff !important;
  padding: 5px 12px;
}
.userList:hover {
  color: #000 !important;
  background-color: #fff;
  border-left: 2px solid red;
}
.userName1:hover {
  cursor: pointer;
}
.userDiv1 {
  background-color: #000;
  text-align: start;
  display: none;
  margin-top: 38px;
  /* margin-left: -118px; */
  width: 163px;
  position: absolute;
}
.userDiv1:hover {
  display: block;
}
.userName1:hover + .userDiv1 {
  display: block !important;
  z-index: 1;
}
.userList1 {
  color: #fff !important;
  padding: 5px 12px;
}
.userList1:hover {
  color: #000 !important;
  background-color: #fff;
  border-left: 1px solid red;
}
.input {
  border: 1px solid #e9d9cd !important;
}
.input:focus {
  border: 1px solid #e9d9cd !important;
}
.textfield {
  border: 1px solid #e9d9cd !important;
}
.textfield:focus {
  border: 1px solid #e9d9cd !important;
}
.fusionnerJE {
  display: flex;
}
.fusionnerJEDiv {
  margin-left: 78px;
}
.premiuerPage {
  margin-top: 5px;
  margin-left: 12px;
  display: flex;
}
.diviser {
  display: flex;
  justify-content: space-between;
}
.navUndermineDiv {
  border-bottom: 1px solid #5d5446;
  margin-top: -10px;
  margin-left: 14px;
}
/* divisor section start*/
.pdfNavDiv.col-lg-1.col-md-12.col-sm-12 {
  width: 117px;
}
.pdfNavDiv.col-lg-11.col-md-12.col-sm-12 {
  width: calc(100% - 117px);
}
.rowGridDiv.col-lg-11.col-md-11.col-sm-12 {
  width: calc(100% - 122px);
  padding: 0 0 !important;
}
.rowGridDiv.col-lg-1.col-md-1.col-sm-12 {
  width: 122px;
  padding: 0 0 !important;
}
.pdfNav.row {
  margin: 0px 0px !important;
}
.leftDiv1.col-sm-1 {
  width: 116px;
}
.leftDiv1.col-sm-11 {
  width: calc(100% - 116px);
  margin-top: -55px;
}
.diviserDepage {
  margin-left: 20px;
}
/* divisor section end */
.dpfsDiv {
  width: 146px;
  height: 207px;
  margin: 0px 11.5px 23px 11.5px;
}
.pdfsDivMains {
  margin: 0px 11.5px 42px 11.5px !important;
}
.pdfsMainDiv {
  display: contents;
}
.compresser.col-lg-2.col-sm-12 {
  width: 187px;
  margin-left: -10px;
}
.compresser.col-lg-10.col-sm-12 {
  width: calc(100% - 187px);
}
.fusionnerLastDiv {
  margin-top: 20px;
}
.dropdown-toggle::after {
  float: right !important;
  margin-top: 10px !important;
}
.leftMainWrapper {
  padding-right: 0px !important;
}
.maxWDiv {
  width: 487px;
}
.txtDiv {
  text-align: center;
  background: rgb(233, 217, 205);
  width: 487px;
  min-height: 36px;
  margin-bottom: 20px;
}
.txtDiv1 {
  text-align: right;
  border: 1px solid #e9d9cd;
  margin-top: -20px;
  width: 487px;
  min-height: 36px;
  margin-bottom: 20px;
  display: flex;
  justify-content: end;
}
.txtDiv1Sapn {
  border: 1px solid rgb(233, 217, 205);
  padding: 4px 8px;
  height: 36px;
  width: 41px;
}
.txtDiv1Sapn:hover{
  cursor: pointer;
}
.bseyyefill:hover{
  cursor: pointer;
}
.comMainDiv {
  display: flex;
}
.comMainDiv1 {
  width: 412px;
}
.comMainDiv1MAin {
  width: 412px;
}
.comMainDiv2 {
  margin-left: 28px;
}
.diviMainDiv {
  text-align: center;
  background: rgb(219, 151, 50);
  min-height: 36px;
}
.diviMainDivP {
  padding: 6px 5px;
  color: rgb(255, 255, 255);
  font-size: 16px;
}
.fusiMainDiv {
  text-align: center;
  background: rgb(238, 226, 217);
}
.fusiMainDivP {
  padding: 6px 5px;
  font-size: 16px;
  color: rgb(93, 84, 70);
}
.proteInputMain {
  border: 1px solid rgb(233, 217, 205);
  display: flex;
  width: 449px;
  height: 37px;
}
/* dashboard start */
.dashboardWrapper {
  display: inline-flex;
  flex-wrap: wrap;
}
.dashboardSinDiv {
  cursor: pointer;
  width: 296px;
  height: 243px;
  padding: 0px 17px;
  text-align: center;
  border-right: 2px solid #5d5446;
  border-bottom: 2px solid #5d5446;
}
.dashboardSecDiv {
  cursor: pointer;
  width: 296px;
  height: 243px;
  padding: 5px 17px;
  text-align: center;
  border-bottom: 2px solid #5d5446;
}
.dashboardSinDivmargin {
  margin-left: 296px;
}
.dashboardSinDiv:hover {
  background-color: #f5f0e6;
}
.dashboardSecDiv:hover {
  background-color: #f5f0e6;
}
.dashboardImg {
  margin-top: 46px;
}
.dashboardMainP1 {
  font-size: 20px;
  color: #db9731;
  font-weight: 700;
  font-family: "Open Sans";
  letter-spacing: 0px;
  margin-top: 9px;
  margin-bottom: 0px;
}
.dashboardMainP2 {
  font-size: 14px;
  letter-spacing: 0px;
  line-height: 22px;
  color: #5d5446;
  font-weight: 400;
  font-family: "Open Sans";
  text-align: center;
  margin-top: 0px;
}
/* dashboard end */
.leftBorderMainDiv {
  border-left: 1px solid #5d5446;
  padding-left: 46px;
}
.pdfABC {
  margin-bottom: 40px;
  margin-left: -16px;
}
.contMainDiv {
  margin-top: -40px;
}
.pdfNavImgMain {
  height: 93px;
  width: 94px;
  margin: 22px 0px 10px 16px;
}
.oVlogoMain {
  width: 270px;
  height: 95px;
  margin: -2px 20px 0px 41px;
}
.ulMainDivFor {
  margin-left: 14px;
}
.textColor {
  color: rgb(93, 84, 70) !important;
  font-size: 16px;
  letter-spacing: 0px;
  font-family: "Open Sans";
  padding-left: 0px !important;
  padding-right: 0px !important;
  margin-right: 25px;
  font-weight: 600;
}
.dashboardDivisDiv {
  padding: 0px 20px;
}
.dashboardFiliDiv {
  padding: 0px 39px;
}
.dashboardFusionnDiv {
  padding: 0px 33px;
}
.dashboardInsererDiv {
  padding: 0px 43px;
}
.dashboardNumeritDiv {
  padding: 0px 32px;
}
.dashboardPivotDiv {
  padding: 0px 35px;
}
.dashboardSinDivmargin {
  padding: 0px 48px;
}
.dashboardSuppriDiv {
  padding: 0px 24px;
}
.topHeaderMainText {
  color: #000000 !important;
  font-size: 25px;
}
.headerTopMain {
  padding: 0 0 !important;
}
.containerFluMainDiv {
  padding: 0 0 !important;
}
.topHTop {
  padding: 0 0 !important;
}
.containerFluMainDivRow {
  margin: 0 0 !important;
}
.sideNameMainImg {
  height: 92px;
  width: 92px;
  margin: 23px 18px 0px 12px;
}
.boxSizeMain.col-lg-4.col-sm-12 {
  width: 526px;
}
.boxSizeMain.col-lg-8.col-sm-12 {
  width: calc(100% - 526px);
}
.textRightPadding {
  padding-right: 77px !important;
}
input[type="number"] {
  border: 1px solid #e9d9cd;
}
.alaxDiv {
  max-width: 69%;
  padding-top: 9px;
}
.modifierMidDivMain {
  width: 319px;
}
.checkBlack input.form-check-input {
  border: 1px solid #5d5446;
}
.diviser1 {
  display: flex;
  padding: 0px 5px;
  margin-bottom: -12px;
  width: 100%;
}
.diviser2 {
  display: flex;
  padding: 0px 5px;
  margin-bottom: -5px;
  width: 100%;
}
.lftDiv.col-lg-2.col-md-12.col-sm-12 {
  width: 210px;
}
.midDiv.col-lg-3.col-md-12.col-sm-12 {
  width: 346px;
}
.rightDiv.col-lg-7.col-md-12.col-sm-12 {
  width: calc(100% - 556px);
}
.pdfBotText {
  font-size: 14px;
  letter-spacing: 0px;
  color: #5d5446;
  font-weight: 700;
  font-family: "Open Sans";
  margin-top: 3px;
}
.pdfNumMain {
  font-size: 14px;
  letter-spacing: 0px;
  color: #5d5446;
  font-weight: 700;
  font-family: "Open Sans";
}
.dropdown-toggle::after {
  font-size: 20px !important;
}
.comNewRDiv {
  width: 487px;
  margin-left: 32px;
}
.comNewtxtDiv:hover {
  cursor: pointer;
}
.comNewtxtDiv {
  background-color: #5d5446;
  min-height: 36px;
  text-align: center;
}
.comNewFlex {
  display: flex;
  justify-content: space-between;
}
.signer1P1 {
  font-size: 16px;
  letter-spacing: 0px;
  color: #5d5446;
  font-weight: 700;
  font-family: "Open Sans";
  margin-top: -7px;
}
.signer1P2 {
  font-size: 20px;
  letter-spacing: 0px;
  color: #db9731;
  font-weight: 700;
  font-family: "Open Sans";
}
.signer1P3 {
  font-size: 16px;
  letter-spacing: 0px;
  color: #5d5446;
  font-weight: 500;
  font-family: "Open Sans";
  margin-top: -21px;
}
.signer1displayF {
  display: flex;
  flex-wrap: wrap;
}
.signer1displayF1 {
  margin-left: 45px;
}
.signer1displayF2 {
  margin-left: 45px;
}
textarea.signautreInput.form-control {
  border: 1px solid #c6b79d !important;
  width: 481px;
  height: 260px;
}
.signer3MainDiv {
  display: flex;
}
.signer3MainDivChild {
  margin-left: 58px;
}
.signer3MainDivHeading {
  font-size: 16px;
  letter-spacing: 0px;
  color: #db9731;
  font-weight: 700;
  font-family: "Open Sans";
  margin-bottom: 3px;
}
.signer4MainDivHeading {
  font-size: 16px;
  letter-spacing: 0px;
  color: #5d5446;
  font-weight: 700;
  font-family: "Open Sans";
}
.signer4Select select.form-select:focus {
  border-color: #c7c8ca !important;
}
.signer4MainDivWrapper {
  display: flex;
  margin-top: 5px;
}
.signer4Select select.form-select {
  width: 201px;
  font-size: 15px;
  border: 2px solid #c7c8ca;
  height: 29px;
  padding-top: 0px;
  margin-left: 19px;
  margin-top: 9px;
  /* background-image: url(/src/img/dropDown.png) !important; */
}
.signer4Wrapp {
  display: flex;
}
.signer4Wrapp2Div {
  margin-left: 52px;
}
.bOneDot {
  transform: translateX(73px) translateY(-240px);
  position: relative;
  z-index: 1;
  height: 20px;
  width: 20px;
}
.blueDot {
  transform: translateX(46px) translateY(-240px);
  position: absolute;
  z-index: 1;
  height: 20px;
  width: 20px;
}
.yellowDot {
  transform: translateX(46px) translateY(-38px);
  position: absolute;
  z-index: 1;
  height: 20px;
  width: 20px;
}
.beigeDot {
  transform: translateX(46px) translateY(-38px);
  position: absolute;
  z-index: 1;
  height: 20px;
  width: 20px;
}
.redDot {
  transform: translateX(46px) translateY(-70px);
  position: absolute;
  z-index: 1;
  height: 20px;
  width: 20px;
}
.searchIconSigner {
  float: right;
  margin-right: 59px;
  margin-top: -113px;
  position: relative;
  color: #818181;
  font-size: 25px;
  transform: rotate(90deg);
}
.checkBoxes1 .form-check-input {
  border: 1px solid #c8b198;
  width: 22px;
  height: 22px;
  border-radius: 1px !important;
}
.signer5DivTxy {
  font-size: 16px;
  letter-spacing: 0px;
  color: #5d5446;
  font-weight: 700;
  font-family: "Open Sans";
}
.solidBoxDate {
  display: flex;
  padding: 10px 0px;
}
.dropDate {
  font-size: 16px !important;
  color: rgb(93, 84, 70) !important;
  border-radius: 0px !important;
  text-align: left !important;
  width: 227px;
  height: 37px;
  margin-left: 10px;
}
.signer5Txte2 {
  font-size: 16px;
  letter-spacing: 0px;
  color: #5d5446;
  font-weight: 700;
  font-family: "Open Sans";
  margin-top: -12px;
  margin-bottom: 9px;
}
.signer5Txte3 {
  font-size: 18px;
  letter-spacing: -1px;
  color: #5d5446;
  font-weight: 700;
  font-family: "Open Sans";
  margin-top: -5px;
}
.signer5TxteItalic {
  font-size: 14px;
  letter-spacing: 0px;
  color: #5d5446;
  font-weight: 400;
  font-style: italic;
  font-family: "Open Sans";
  margin-top: -14px;
}
.checkBoxes2 .form-check-input {
  border: 1px solid #c8b198;
  width: 28px;
  height: 27px;
  border-radius: 1px !important;
}
.signer5Txte4 {
  font-size: 14px;
  letter-spacing: 0px;
  color: #5d5446;
  font-weight: 400;
  font-style: italic;
  font-family: "Open Sans";
  margin-bottom: 0px;
}
.signer5Txte5 {
  font-size: 14px;
  letter-spacing: 0px;
  color: #5d5446;
  font-weight: 400;
  font-style: italic;
  font-family: "Open Sans";
  margin-bottom: 0px;
  margin-top: -14px;
}
.signer5Txte6 {
  font-size: 16px;
  letter-spacing: -1px;
  color: #5d5446;
  font-weight: 700;
  font-family: "Open Sans";
  margin-top: 3px;
  margin-bottom: 0px;
}
.dateClass {
  font-size: 16px;
  letter-spacing: 0px;
  color: #5d5446;
  font-weight: 700;
  font-family: "Open Sans";
}
.dashLink {
  text-decoration: none;
}
.boxSizeMainss {
  margin-top: 19px !important;
}
.comNewRDivss {
  border-left: 1px solid #5d5446 !important;
  margin-left: 0px;
  padding-left: 32px;
}
.textHover:hover {
  cursor: pointer;
}
.converterIcons {
  position: absolute;
  margin-left: 63px;
  margin-top: -31px;
  margin-bottom: 0px;
}
.nav-link:hover + .converterIcons {
  color: #db9732 !important;
  margin-top: -25px;
}
.textColor1 {
  color: #5d5446 !important;
  font-size: 16px;
  letter-spacing: 0px;
  font-family: "Open Sans";
  text-align: left;
  padding-left: 0px;
  margin-right: 25px;
  border-bottom: none !important;
}
.pdfNAmes {
  text-align: center;
  width: 100%;
}
.pdfnameWrapper {
  display: flex;
}
.convertBtnns {
  width: 300px;
  height: 65px;
}
.convertButtonRight {
  display: grid;
  align-items: end;
  background: #f3f0ec;
  min-height: 106.5vh !important;
  /* padding-bottom: 11px; */
  width: 20%;
  padding: 10px 2%;
}
.signer8secText {
  font-size: 16px;
  letter-spacing: 0px;
  color: #5d5446;
  font-weight: 700;
  font-family: "Open Sans";
  margin-top: 25px;
  margin-bottom: 0px;
}
.signer8DocDiv {
  max-width: 1003px;
  min-height: 63px;
  background-color: #faf5f2;
  margin-top: 20px;
  margin-bottom: 0px;
  display: flex;
  flex-wrap: wrap;
}
.signer8DocDiv1 {
  max-width: 1003px;
  min-height: 63px;
  background-color: #faf5f2;
  margin-top: 5px;
  margin-bottom: 0px;
  display: flex;
  flex-wrap: wrap;
}
.arrowDiv {
  display: grid;
  margin-left: 20px;
  padding-top: 16px;
}
.upDownArrow {
  color: #d1b29f;
}
.upDownArrow1 {
  color: #5d5446;
}
.signer8Circle {
  height: 33px;
  width: 33px;
  margin-left: 15px;
  margin-top: 15px;
}
.signer8inputs {
  border: 1px solid #e9d9cd !important;
  width: 241px !important;
  height: 40px;
  border-radius: 0px !important;
  margin-left: 14px;
  margin-top: 10px;
}
.signer8inputEmail {
  border: 1px solid #e9d9cd !important;
  width: 306px !important;
  height: 40px;
  border-radius: 0px !important;
  margin-left: 10px;
  margin-top: 10px;
}
.btn-check:focus + .btn,
.btn:focus {
  box-shadow: none !important;
}
.signer8Dropdown {
  border: 1px solid #e9d9cd;
  overflow: auto;
  color: rgb(93, 84, 70);
  max-width: 134px;
  min-height: 40px;
  text-align: left;
  border-radius: 0px !important;
  background: #ffff;
  margin-left: 10px;
  margin-top: 10px;
}
.icoLockLightIcon {
  height: 26px;
  width: 21px;
  margin-left: 50px;
  margin-top: 15px;
}
.pictoGsmSignerOnIcon {
  height: 26px;
  width: 17px;
  margin-left: 13px;
  margin-top: 15px;
}
.pictoSignatureSignerOnIcon {
  height: 26px;
  width: 18px;
  margin-left: 15px;
  margin-top: 15px;
}
.closeIconIcon {
  height: 19px;
  width: 19px;
  margin-left: 34px;
  margin-top: 20px;
}
.motDePasse {
  border: 1px solid #e9d9cd;
  width: 281px !important;
  height: 42px;
  border-radius: 0px;
  margin-left: 38px;
  margin-top: 5px;
}
.telephoneInput {
  border: 1px solid #e9d9cd;
  width: 281px !important;
  height: 42px;
  border-radius: 0px;
  margin-left: 18px;
  margin-top: 5px;
}
#segmented-button-dropdown-1 {
  border-right: 1px solid #e9d9cd;
  border-left: none;
  border-top: none;
  border-bottom: none;
  height: 40px;
  background-color: #fdfaf6;
  color: #c6b79d;
  padding-left: 8px;
  padding-top: 0px;
}
button.btn.btn-primary {
  height: 40px !important;
  background-color: #fdfaf6;
  border: none;
}
.pictoGsmSignerOnIcon12 {
  height: 21px;
  width: 14px;
  margin-left: 3px;
  margin-top: 1px;
}
.pictoGsmSignerOnIcon12Txt {
  font-size: 14px;
  letter-spacing: 0px;
  line-height: 40px;
  color: #5d5446;
  font-weight: 400;
  font-family: "Open Sans";
  margin-left: 10px;
  margin-top: -7px;
}
.de-signatureDrop {
  border: 1px solid #e9d9cd;
  border-radius: 0px !important;
  width: 279px;
  height: 42px;
  margin-top: 5px;
  margin-left: 18px;
}
.motDePasseMainDiv {
  display: flex;
  flex-wrap: wrap;
}
.icoCloseLight {
  width: 19px;
  height: 19px;
  margin-top: 15px;
  margin-left: 7px;
}
.appliquerWrapper {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}
.appliquerWrapperDiv1:hover {
  cursor: pointer;
}
.appliquerWrapperDiv1 {
  background-color: #c6b79d;
  width: 736px;
  height: 63px;
  display: flex;
  align-items: center;
}
.appliquerWrappertxt1 {
  margin: auto auto;
  font-size: 20px;
  letter-spacing: 0px;
  color: #ffffff;
  font-weight: 700;
  font-family: "Open Sans";
}
.appliquerWrapperDiv2:hover {
  cursor: pointer;
}
.appliquerWrapperDiv2 {
  background-color: #5d5446;
  min-width: 260px;
  height: 63px;
  display: flex;
  align-items: center;
  margin-left: 7px;
}
.appliquerWrappertxt2 {
  margin: auto auto;
  font-size: 24px;
  letter-spacing: -1px;
  color: #ffffff;
  font-weight: 700;
  font-family: "Open Sans";
}
.Paramétrez {
  font-size: 20px;
  letter-spacing: 0px;
  color: #c6b79d;
  font-weight: 700;
  font-family: "Open Sans";
}
.divWrappersss {
  margin-left: 20px;
  margin-top: 7px;
}
.checknewBoxs input.form-check-input {
  height: 36px !important;
  width: 36px !important;
  margin-top: 10px;
}
.definirDiv {
  font-size: 16px;
  letter-spacing: 0px;
  color: #5d5446;
  font-weight: 700;
  font-family: "Open Sans";
  margin-bottom: 0px;
}
.definirDiv1 {
  font-size: 16px;
  letter-spacing: 0px;
  color: #5d5446;
  font-weight: 400;
  font-family: "Open Sans";
  margin-bottom: 0px;
}
.signer8LeftDiv {
  width: calc(100% - 566px) !important;
}
.signer8RightDiv {
  width: 566px !important;
}
.definirDivWrapper {
  margin-left: 15px;
}
.definirDiv122 {
  font-size: 16px;
  letter-spacing: 0px;
  line-height: 40px;
  color: #5d5446;
  font-weight: 700;
  font-family: "Open Sans";
  margin-left: 7px;
  margin-top: 3px;
}
.signer8inputs2nd {
  border: 1px solid #e9d9cd !important;
  width: 452px !important;
  height: 57px;
  border-radius: 0px !important;
  margin-left: 52px;
  margin-top: 10px;
}
.signer8inputs3rd {
  border: 1px solid #e9d9cd !important;
  width: 452px !important;
  height: 90px;
  border-radius: 0px !important;
  margin-left: 52px;
  margin-top: 10px;
}
.signer8inputs4rth {
  border: 1px solid #e9d9cd !important;
  width: 274px !important;
  height: 40px;
  border-radius: 0px !important;
  margin-left: 52px;
  margin-top: 10px;
}
.TéléchargerLeLogoWrapper {
  background-color: #5d5446;
  border: 1px solid #e9d9cd;
  width: 167px;
  height: 40px;
  margin-left: 10px;
  text-align: center;
  margin-top: 10px;
}
.TéléchargerLeLogoWrapper :hover{
  cursor: pointer;
}
.TéléchargerLeLogoTxt {
  font-size: 14px;
  letter-spacing: 0px;
  line-height: 40px;
  color: #ffffff;
  font-weight: 700;
  font-family: "Open Sans";
}
.disflex{
  display: flex;
}
.inputDivsMain{
  display: flex;
}
.inputSigner8s{
  width: 65px;
  height: 40px;
  margin-top: 5px;
  margin-left: 39px;
}
.dpfsDivConvert {
  width: 146px;
  height: 207px;
  margin: 0px auto;
}
.spinrole{
  margin-left: 20px;
  margin-bottom: -8px;
}
.gdhover:hover{
  cursor: pointer;
}
.positionsGrill{
  height: 104px;
  width: 104px;
  border: 2px solid #5d5446;
}
.positionsGrillDiv{
  width: 36px;
  height: 35px;
  border: 1px dashed #5d5446;
  margin-top: -1px;
  margin-left: -1px;
  margin-right: -1px;
  text-align: center;
}
.filigranerpositionsGrillDiv{
  width: 36px;
  height: 35px;
  border: 1px dashed #5d5446;
  margin-top: -1px;
  margin-left: -1px;
  margin-right: -1px;
  text-align: center;
}
.de_select:hover{
  cursor: pointer;
}
.topleft {
  top: 35px;
  left: 15px;
}
.topcenter {
  top: 35px;
  left: calc(50% - 10px);
}
.topmiddle {
  top: 35px;
  left: calc(50% - 10px);
}
.topright{
  top: 35px;
  left: calc(100% - 35px);
}
.middleleft{
  top: 114px;
  left: 15px;
}
.middlecenter{
  top: 114px;
  left: calc(50% - 10px);
}
.middleright{
  top: 114px;
  left: calc(100% - 35px);
}
.bottomleft{
  top: 192px;
  left: 15px;
}
.bottomcenter{
  top: 192px;
  left: calc(50% - 10px);
}
.bottommiddle{
  top: 192px;
  left: calc(50% - 10px);
}
.bottomright{
  top: 192px;
  left: calc(100% - 35px);
}
.dropdown-menu.show.dropdown-menu-dark {
  margin-left: -170%;
}
.dropBtnMenuIcon a::after{
  display: none !important;
}
.fusionnerView{
  display: flex !important;
}












/*
  t- #5d5446
  g- #E9D9CC
  y- #DB9732
*/

/* res */

@media screen and (max-width: 1842px) {
  .appliquerWrapperDiv1{
    width: 100%;
  }
  .appliquerWrapperDiv2{
    margin-top: 10px;
    margin-left: 0px;
  }
}
@media screen and (max-width: 1832px) {
  .motDePasseMainDivmain3{
    margin-left: 20px;
    margin-top: 10px;
  }
}
@media screen and (max-width: 1825px) {
  .closeIconIcon{
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 1775px) {
  .maxWDiv {
    width: auto;
  }
}
@media screen and (max-width: 1769px) {
  .txtDiv {
    width: 100%;
  }
  .txtDiv1 {
    width: 100%;
  }
  .dashboardSinDivmargin {
    margin-left: 0px;
  }
  .dashboardSecDiv {
    padding: 0px 17px;
    max-width: 296px;
  }
  .dashboardSinDiv {
    padding: 0px 17px;
    max-width: 296px;
  }
  .dashboardExtraDiv {
    border-right: none;
  }
  .dashboardModiDiv {
    border-right: none;
  }
  .dashboardSigneDiv {
    border-right: none;
  }
  .dashboardFiliDiv {
    border-right: 1px solid #5d5446;
  }
  .dashboardPivotDiv {
    border-right: 1px solid #5d5446;
  }
  .dashboardSuppriDiv {
    border-right: 1px solid #5d5446;
  }
}
@media screen and (max-width: 1755px) {
  /* .diviser {
    display: grid;
  } */
  .aPartir {
    margin-left: 0px;
  }
}
@media screen and (max-width: 1661px) {
  .nav-link:hover + .converterIcons {
    margin-top: -31px;
  }
  .active .converterIcons {
    color: #db9732 !important;
    margin-top: -31px;
  }
  .modifierMidDivMain {
    width: auto !important;
  }
  .ulMainDivFor {
    margin-left: 0px;
  }
  .navTexts {
    margin-left: 0px;
  }
  .nav li {
    border-bottom: 1px solid #5d5446;
  }
  .convertirDropLi {
    border-bottom: none !important;
  }
  .navUndermineDiv {
    display: none;
  }
  .nav-link:hover {
    padding-bottom: 6px;
    margin-bottom: -3px;
  }
  .active a {
    margin-bottom: -3px;
    padding-bottom: 6px;
  }
  .pdfABC {
    margin-left: 0px;
  }
}
@media screen and (max-width: 1650px) {
  .numeroterdelaPage {
    display: grid;
  }
  .alaPage1 {
    margin-left: 81px;
  }
}
@media screen and (max-width: 1648px) {
  .signer8Dropdown{
    margin-bottom: 10px;
  }
  .inputDivsMain{
    display: grid;
  }
}
@media screen and (max-width: 1600px) {
  .positionnement {
    display: grid;
  }
  .filigranerimage {
    display: grid;
  }
  .filigranerimagediv {
    width: auto;
  }
}
@media screen and (max-width: 1576px) {
  .fichierDeAla {
    margin-left: -9px;
  }
}
@media screen and (max-width: 1564px) {
  /* .fusionnerJE {
    display: grid;
  } */
}
@media screen and (max-width: 1558px) {
  .leftDiv1.col-sm-11 {
    margin-top: -20px;
  }
}
@media screen and (max-width: 1515px) {
  .editorDiv1 {
    width: 60%;
  }
  .editorDiv2 {
    width: 95%;
  }
  .numeroterdelaPage {
    display: grid;
  }
  .alaPage1 {
    margin-left: 81px;
  }
}
@media screen and (max-width: 1509px) {
  .motDePasseMainDivmain2{
    margin-left: 20px;
    margin-top: 10px;
  }
}
@media screen and (max-width: 1505px) {
  /* .signer8Dropdown{
    margin-left: 57px;
  }
  .signer8inputEmail{
    margin-left: 98px;
  } */
}
@media screen and (max-width: 1490px) {
  .diviser1 {
    margin-bottom: 1px;
  }
  .diviser2 {
    margin-bottom: 5px;
  }
  /* .fichierDeAla {
    display: grid;
  } */
  .diviserLAPageDiv11 {
    margin-left: 14px;
  }
  .diviserLAPage {
    margin-left: 17px;
  }
  .aPartir {
    margin-left: 0px;
  }
}
@media screen and (max-width: 1473px) {
  .dashboardDivisDiv {
    border-right: none;
  }
  .dashboardFusionnDiv {
    border-right: none;
  }
  .dashboardNumeritDiv {
    border-right: none;
  }
  .dashboardExtraDiv {
    border-right: 1px solid #5d5446;
  }
  .dashboardModiDiv {
    border-right: 1px solid #5d5446;
  }
}
@media screen and (max-width: 1466px) {
  .contMainDiv {
    margin-top: -20px;
  }
}
@media screen and (max-width: 1448px) {
  .positionnementDiv {
    display: grid;
  }
}
@media screen and (max-width: 1445px) {
  /* .signer8Dropdown{
    margin-left: 98px;
  } */
}
@media screen and (max-width: 1420px) {
  .Numerotations {
    display: grid !important;
    justify-content: flex-start !important;
    margin-left: 10px;
  }
  /* .midSearch {
    margin-top: -120px;
  } */
}
@media screen and (max-width: 1342px) {
  /* .diviser {
    display: grid !important;
  } */
  .diviserLAPageDiv {
    margin-top: 5px;
  }
}
@media screen and (max-width: 1330px) {
  .imgCenterMainDiv {
    text-align: center;
  }
  .pdfNavImgMain {
    margin: 10px auto;
  }
  .sideNameMainImg {
    margin: 10px auto;
  }
  .pdfABC {
    padding: 0 39px !important;
  }
  .dashboardWrapper {
    justify-content: center;
  }
  .dashboardDivisDiv {
    border-right: 1px solid #5d5446;
  }
  .dashboardFusionnDiv {
    border-right: 1px solid #5d5446;
  }
  .dashboardNumeritDiv {
    border-right: 1px solid #5d5446;
  }
  .dashboardExtraDiv {
    border-right: none;
  }
  .dashboardModiDiv {
    border-right: none;
  }
  /* .dashboardWrapper {
    margin-left: 51px;
  } */
  .rowGrid {
    display: grid !important;
  }
  .pdfNav {
    display: grid !important;
  }
  .Sidenav {
    min-height: 12vh !important;
  }
  .backgroundRes {
    background: #5d5446 !important;
  }
  .back {
    border-top-right-radius: 25px;
  }
  .rowGridDiv {
    width: 100% !important;
  }
  .pdfNavDiv {
    width: 100% !important;
  }
  .leftDiv1 {
    width: 100% !important;
  }
  /* .borderNav{
    margin-right: 20px;
  } */
}
@media screen and (max-width: 1298px) {
  /* .filigranerimage {
    display: grid;
  } */
  .editor {
    display: grid;
  }
  .editorDiv1 {
    width: 100% !important;
  }
  .editorDiv2 {
    width: 100% !important;
  }
  .filigranerimagediv {
    width: 100%;
  }
}
@media screen and (max-width: 1280px) {
  .dashboardDivisDiv {
    border-right: none;
  }
  .dashboardFusionnDiv {
    border-right: none;
  }
  .dashboardNumeritDiv {
    border-right: none;
  }
  .dashboardExtraDiv {
    border-right: 1px solid #5d5446;
  }
  .dashboardModiDiv {
    border-right: 1px solid #5d5446;
  }
}
@media screen and (max-width: 1200px) {
  .filigranerimagediv {
    width: auto !important;
  }
  .filigranerimage {
    display: grid;
  }
  .numerotationsDiv2 {
    display: grid;
    justify-content: flex-start;
  }
  .alaPage {
    margin-left: 52px;
    margin-top: 5px;
  }
  .premiuerPage {
    margin-left: 13px;
  }
  .positionnement {
    display: grid;
  }
  .affichage {
    display: grid;
  }
  .texteEnFiligrane {
    display: grid !important;
  }
  .affichageDiv {
    display: grid;
  }
  .textfield {
    width: "60%";
  }
}
@media screen and (max-width: 1103px) {
  .dpfsDivBott {
    margin-bottom: 44px;
  }
  .signer1displayF0 {
    margin-left: 45px;
  }
}
@media screen and (max-width: 1100px) {
  .proteInputMain {
    width: 90%;
    height: auto;
  }
  .editor {
    display: grid !important;
  }
  .comNewRDiv {
    margin-left: 0px;
  }
}
@media screen and (max-width: 1053px) {
  .comNewRDiv {
    width: 100%;
  }
  .signer8LeftDiv {
    width: 100% !important;
  }
  .signer8RightDiv {
    width: 100% !important;
  }
  .motDePasseMainDivmain1{
    margin-top: 10px;
  }
}
@media screen and (max-width: 1029px) {
  .fichierDeAla {
    margin-left: 0px;
  }
}
@media screen and (max-width: 991px) {
  .signer4Wrapp {
    display: grid;
  }
  .signer4Wrapp2Div {
    margin-left: 0px;
  }
  .signer3MainDiv {
    display: grid;
  }
  .signer3MainDivChild {
    margin-left: 0px;
    margin-top: 10px;
  }
  .LMRMainDiv {
    display: grid !important;
  }
  .lftDiv.col-lg-2.col-md-12.col-sm-12 {
    width: 100%;
  }
  .midDiv.col-lg-3.col-md-12.col-sm-12 {
    width: 100%;
  }
  .rightDiv.col-lg-7.col-md-12.col-sm-12 {
    width: 100%;
  }
  .comMainDiv1MAin {
    width: 100%;
  }
  .textRightPadding {
    padding-right: 0px !important;
  }
  .boxSizeMain.col-lg-4.col-sm-12 {
    width: 100% !important;
  }
  .boxSizeMain.col-lg-8.col-sm-12 {
    width: 100% !important;
  }
  .leftBorderMainDiv {
    padding-left: 20px;
  }
  .comMainDiv {
    display: grid;
  }
  .comMainDiv1 {
    width: auto;
  }
  .comMainDiv2 {
    margin-left: 0;
  }
  .leftMainWrapper {
    padding-right: 0px !important;
  }
  .fusionnerLastDiv {
    margin-top: 40px !important;
  }
  .compresser.col-lg-2.col-sm-12 {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .compresser.col-lg-10.col-sm-12 {
    width: 100%;
  }
  .nav {
    display: flex;
    justify-content: center;
  }
  .filigranerimage {
    display: flex;
  }
  .fusionnerJE {
    display: flex;
  }
  .fusionnerJEDiv {
    margin-left: 78px;
  }
  .affichageDiv {
    display: flex;
  }
  .positionnement {
    display: flex;
  }
  .numeroterdelaPage {
    display: flex;
  }
  .numerotationsDiv2 {
    display: flex;
  }
  .aLaLaPage {
    margin-left: 10px;
  }
  .premier {
    display: flex;
  }
  .positionnementDiv {
    display: flex;
  }
  .fichierDeAla {
    display: flex;
  }
  .diviser {
    display: flex;
  }
  .signerDiv {
    display: grid;
  }
  .userDiv {
    margin-left: 0px;
  }
  .userDiv1 {
    margin-left: 112px;
  }
  .loginRoot {
    height: 100%;
  }
  .avi {
    max-width: 146px !important;
    height: 207px !important;
    margin: 10px auto;
  }
  .react-pdf__Page__canvas {
    max-width: 146px !important;
    height: 207px !important;
  }
  .compresser .react-pdf__Page__canvas {
    width: 146px !important;
    height: 207px !important;
  }
  .numeroter .react-pdf__Page__canvas {
    max-width: 146px !important;
    max-height: 207px !important;
  }
  .search {
    margin-top: -31px;
    margin-right: 9px;
  }
  .searchIcon {
    margin-top: -33px;
    margin-right: 12px;
  }
  .dotIcon {
    margin-top: -18px;
    margin-right: 6px;
  }
  .midSearch {
    margin-top: -218px !important;
  }
  .rotation {
    display: grid !important;
  }
  .scrollPDF {
    height: "50vh";
  }
  .scrollPDF1 {
    height: "50vh";
  }
}
@media screen and (max-width: 984px) {
  .dashboardConverDiv {
    border-right: none;
  }
  .dashboardExtraDiv {
    border-right: none;
  }
  .dashboardModiDiv {
    border-right: none;
  }
  .dashboardPivotDiv {
    border-right: none;
  }
  .dashboardDivisDiv {
    border-right: 1px solid #5d5446;
  }
  .dashboardNumeritDiv {
    border-right: 1px solid #5d5446;
  }
}
@media screen and (max-width: 840px) {
  .delapage {
    display: grid !important;
  }
}
@media screen and (max-width: 767px) {
  .tableDiv {
    display: grid !important;
  }
  .signerDiv2 {
    display: grid;
  }
  .signerDiv3 {
    margin-top: 10px;
  }
  .scrollPDF {
    height: "30vh";
  }
  .scrollPDF1 {
    height: "30vh";
  }
}
@media screen and (max-width: 688px) {
  .dashboardCompreDiv {
    border-top: 1px solid #5d5446;
    border-left: 1px solid #5d5446;
  }
  .dashboardConverDiv {
    border-right: 1px solid #5d5446;
    border-left: 1px solid #5d5446;
  }
  .dashboardDivisDiv {
    border-left: 1px solid #5d5446;
  }
  .dashboardExtraDiv {
    border-right: 1px solid #5d5446;
    border-left: 1px solid #5d5446;
  }
  .dashboardFiliDiv {
    border-left: 1px solid #5d5446;
  }
  .dashboardFusionnDiv {
    border-right: 1px solid #5d5446;
    border-left: 1px solid #5d5446;
  }
  .dashboardInsererDiv {
    border-left: 1px solid #5d5446;
  }
  .dashboardModiDiv {
    border-right: 1px solid #5d5446;
    border-left: 1px solid #5d5446;
  }
  .dashboardNumeritDiv {
    border-left: 1px solid #5d5446;
  }
  .dashboardPivotDiv {
    border-right: 1px solid #5d5446;
    border-left: 1px solid #5d5446;
  }
  .dashboardSinDivmargin {
    border-left: 1px solid #5d5446;
  }
  .dashboardSigneDiv {
    border-right: 1px solid #5d5446;
    border-left: 1px solid #5d5446;
  }
  .dashboardSuppriDiv {
    border-left: 1px solid #5d5446;
  }
}
@media screen and (max-width: 650px) {
  .fusionnerh5 {
    margin-top: 10px;
  }
  .textfield {
    margin-top: -16px;
  }
}
@media screen and (max-width: 595px) {
  .dpfsDiv {
    margin: 20px auto;
  }
}
@media screen and (max-width: 581px) {
  .numeroterdelaPage {
    display: grid;
  }
}
@media screen and (max-width: 575px) {
  .inputSigner8s{
    margin-left: 17px;
  }
  .disflex{
    display: grid;
  }
  .signer8inputs2nd{
    width: auto !important;
  }
  .signer8inputs3rd{
    width: auto !important;
  }
  textarea.signautreInput.form-control {
    width: 100%;
  }
  .alaxDiv {
    max-width: 100%;
  }
  .diviser {
    display: grid;
  }
  .buttonDiv {
    display: grid;
  }
  .facebookBtn {
    width: 100%;
    margin: 5px 0;
  }

  .txtDiv {
    max-width: 100% !important;
  }
  .fileselector {
    display: grid !important;
  }
  .importSM {
    display: flex !important;
    justify-content: center;
  }
  .drive {
    margin-top: 5px;
    margin-right: 5px;
  }
  /* .btnhover {
    background: #e5322d !important;
    max-width: 60vw !important;
    height: 80px !important;
    padding: 24px 56px !important;
    display: inline-flex !important;
    align-items: center !important;
    justify-content: center !important;
    font-size: 28px !important;
    font-weight: 600 !important;
    color: #ffffff !important;
    border-radius: 10px !important;
    vertical-align: middle !important;
    line-height: 32px !important;
  } */
}
@media screen and (max-width: 505px) {
  .filigranerimage {
    display: grid;
  }
}
@media screen and (max-width: 495px) {
  .signerDiv3 {
    width: auto;
  }
  .fusionnerJE {
    display: grid;
  }
  .fusionnerJEDiv {
    margin-left: 0px;
    margin-top: 13px;
  }
  .fichierDeAla {
    display: grid;
  }
  .positionnement {
    display: grid;
  }
  .affichageDiv {
    display: grid;
  }
  .positionnementDiv {
    display: grid;
  }
  .premier {
    display: grid;
  }
  .numerotationsDiv2 {
    display: grid;
  }
  .aLaLaPage {
    margin-left: 2px;
  }
}
@media screen and (max-width: 469px) {
  .signer8inputs{
    width: auto !important;
  }
  .signer8inputEmail{
    width: auto !important;
  }
  .signer8inputs4rth{
    width: auto !important;
  }
  .motDePasse{
    margin-left: 0px;
    width: 100% !important;
  }
  .telephoneInput{
    margin-left: -19px;
    width: 100% !important;
  }
  .TéléchargerLeLogoWrapper{
    margin-left: 51px;
  }
}
@media screen and (max-width: 455px) {
  .signer1displayF0 {
    margin-left: 0px;
  }
  .signer1displayF1 {
    margin-left: 0px;
  }
  .signer1displayF2 {
    margin-left: 0px;
  }
}
@media screen and (max-width: 436px) {
  .diviser {
    padding: 0 3px;
  }
  .delapage111 {
    padding: 0 3px;
  }
}
@media screen and (max-width: 425px) {
  .unlWrapper {
    margin-left: 10px;
    margin-bottom: 20px;
  }
  .textColor {
    padding-left: 15px !important;
    margin-right: 0;
  }
  .dashboardWrapper {
    margin-left: 0px;
  }
  .dashboardSecDiv {
    width: 252px;
    margin: 0 auto;
  }
  .dashboardSinDiv {
    width: 252px;
    margin: 0 auto;
  }
  .oVlogoMain {
    margin: 4px 10px;
  }
  .converterIcons{
    margin-left: 88px;
  }
}
@media screen and (max-width: 416px) {
  .extraire {
    display: grid !important;
  }
  .aLaPage {
    margin-left: 3px;
  }
  .aLaLaPage {
    margin-left: 2px;
  }
}
@media screen and (max-width: 402px) {
  .motDePasseMainDivmain3{
    margin-left: 0px;
  }
  .de-signatureDrop{
    margin-left: 0px;
    width: 100% !important;
  }
}
@media screen and (max-width: 380px) {
  .solidBoxDate {
    display: grid;
  }
  .dropDate {
    margin-left: 0px;
    margin-top: -8px;
    margin-bottom: 10px;
  }
  .signer4MainDivWrapper {
    display: grid;
  }
  .signer4Select select.form-select {
    margin-left: 0px;
    margin-top: -12px;
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 374px) {
  .signer8inputs{
    margin-left: 29px;
  }
  .signer8inputEmail{
    margin-left: 29px;
  }
  .signer8Dropdown{
    margin-left: 29px;
  }
  .motDePasseMainDivmain3{
    margin-left: 0px;
  }
  .de-signatureDrop{
    margin-left: 0px;
    width: 100% !important;
  }
  .divWrappersss{
    margin-left: 0px;
  }
}
@media screen and (max-width: 329px) {
  /* .premiuerPage {
    margin-left: 0px !important;
  } */
  .signerDiv2 {
    margin-left: -10px;
  }
  .diviserDepage {
    margin-left: 6px;
  }
  .diviserLAPageDiv11 {
    margin-left: 0px;
  }
}
@media screen and (max-width: 320px) {
  .premiuerPage {
    margin-left: -3px !important;
  }
}
